<template>
    <div id="app">
        <calendar-list src="$root.src"></calendar-list>
    </div>
</template>

<script>
import CalendarList from "./components/CalendarList.vue"

export default {
    name: "App",
    props: {},
    components: {
        CalendarList
    }
}
</script>