import "./app.css"

import Vue from "vue"
import App from "./App.vue"

Vue.config.productionTip = false

const target = document.querySelector("#app")

const app = new Vue({
    propsData: { ...target.dataset },
    props: ["src", "proxy"],
    render: h => h(App),
}).$mount("#app")


export default app